import { createGlobalStyle } from 'styled-components';

export const theme = {
  // brand utility colors
  primaryColor: '#73a94e', // the main identity color
  primaryTextColor: 'white', // the text color that works best with primary color
  primaryHoverColor: '#006083', // the primary color's complementary background color when hovering over an element
  primaryActiveColor: '#004E6A', // the primary color's complementary background color when clicking on an element
  secondaryColor: '#e1e1e1', // a secondary color that compliments but does not detract from the primary brand color
  secondaryHoverColor: '#46596E',
  secondaryActiveColor: '#50657D',
  tertiaryColor: '#f7f7f7', // neutral used for body backgroundcolor
  black: '#555555', // used in nav links on light backgrounds
  white: '#ffffff', // used in nav links and links on dark backgrounds
  logoTransparent: 'true',
  // neutral UI colors
  colorgrayxxlight: '#fafafa',
  colorgrayxlight: '#f7f7f7',
  colorgraylight: '#f3efeb',
  colorgraydark: '#8a8a8a',

  // utility colors
  success: '#73a94e', // used in pay with bank account
  alert: '#a41e22',
  colorinactive: '#666666', // used in payment accordion

  // brand accent assignments
  //   accent1: primaryColor, // agent cap
  //   accent2: primaryColor, // active wizard header
  //   accent3: colorinactive, // inactive wizard header
  //   accent4: colorgrayxxlight,
  //   accent5: colorgraylight, // backgroundstripe

  light: '#f1f2f3',
  //   bordercolor: light,

  link: '#002c73', // used for most links
  textblack: '#5a5a5a',

  //   bgActiveWizardPanel: accent1,
  //   bgInactiveWizardPanel: accent3,

  //   bgActiveWizardCheck: accent2,
  //   bgInactiveWizardCheck: accent2,

  //   bgPaymentSummaryPanel: accent2,
};

export const GlobalStyle = createGlobalStyle`
.MuiStep-root {
  button {
    box-shadow: none;
    :hover {
      background-color: initial;
    }
    :disabled {
      background-color: initial;
    }
    :active{
      background-color: initial;
    }
  }
} /* needed for stepper material components*/
button {
  border-radius: 4px;
  box-shadow: 0 1px 2px 1px rgba(40, 51, 63, 0.24);
  background-color: ${props => (props.theme.primaryColor ? props.theme.primaryColor : 'gray')};
  :hover{
    background-color: ${props =>
      props.theme.primaryHoverColor
        ? props.theme.primaryHoverColor
        : props.theme.primaryColor
        ? props.theme.primaryColor
        : 'gray'};
  }
  :active{
    background-color: ${props => props.theme.primaryActiveColor};
  }
  :disabled{
    background-color: ${props => props.theme.colorgraydark};
  }
  border: none;

}
.cancel {
    background-color: ${props => (props.theme.colorgraydark ? props.theme.colorgraydark : 'gray')};
    color: white;
    :hover{
      background-color: gray;
    }
    :active{
      background-color: #646464;
    }
  }
body {
  background-color: ${props => props.theme.gray};
}
.screenreader-text {
  position: absolute;
  left: -999px;
  width: 1px;
  height: 1px;
  top: auto;
}
.screenreader-text:focus {
  color: black;
  display: inline-block;
  height: auto;
  width: auto;
  position: static;
  margin: auto;
}
#logo {
  background-color: white;
}
#loggedOutLogo {
  width: auto;
  height: 170px;
  padding: 30px;
}
#logoLink img,
.navbar-brand img {
  background-color: white;
}

a {
  font-weight: 400;
}
a[type="button"]{
    -webkit-appearance: initial;
    display: inline-block;
}
.navbar {
  &-fixed-top {
    background-color: white;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    @media (max-width: 767px) {
    }
  }
  &-header {
    .nav {
      a:not(#logout) {
        color: black;
        text-transform: uppercase;
        &:active,
        &:hover {
          color: black;
        }
      }
      .btn-logout {
        margin-left: 0.875rem;
        margin-right: 0.5rem;
        border-color: black;
        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
          border-color: rgba(0, 0, 0, 0.1);
        }
        &--link {
          margin: 0;
          padding-left: 0.875rem;
          padding-right: 0.875rem;
          color: black;
          text-transform: uppercase;
          font-weight: normal;
          font-size: 14px;
          line-height: 2;
          &:focus {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.navbar-toggle {
  @media (max-width: 767px) {
    .icon-bar {
      background-color: ${props => props.theme.secondaryColor};
    }
  }
}

.btn#ConfirmPayment {
  background-color: #ffffff;
  color: ${props => props.theme.primaryColor} !important;
  &:hover {
    background-color: rgba(255, 255, 255, 0.8) !important;
    color: ${props => props.theme.secondaryColor} !important;
    border-color: rgba(255, 255, 255, 0.8) !important;
  }
}

#changePassword {
  margin-top: 1.125rem;
}

#forgotPasswordPanel {
  background-color: ${props => props.theme.gray};
}
#forgotUserIdPanel {
  background-color: ${props => props.theme.gray};
}
#signupPanel {
  background-color: ${props => props.theme.gray};
}

.footer-link {
  color: ${props => props.theme.gray};
  &:hover {
    color: ${props => props.theme.gray};
  }
}

#layout {
  background-color: ${props => props.theme.gray};
}

.background {
  background-color: ${props => props.theme.gray};
}

.btn-brand-inactive {
  border: 1px solid ${props => props.theme.gray};
  border-radius: 5px;
}

.btn-brand {
  background-color: ${props => props.theme.gray};
  border: 1px solid ${props => props.theme.gray} !important;
  border-radius: 5px;
  color: ${props => props.theme.gray};
  box-shadow: 1px 1px 3px ${props => props.theme.black};
}

.text-brand {
  color: ${props => props.theme.gray};
}

label.radio {
  cursor: pointer;
}

a {
  color: ${props => props.theme.gray};

  .event {
    color: ${props => props.theme.gray};
  }
}
#TemplateLogo{

}
#needHelp {
  & > tbody > tr {
    &:first-child {
      td {
        border-top: none !important;
      }
    }
  }

  td {
    vertical-align: top;
  }

  .needhelp {
    &__contact {
      &--label {
        font-weight: 600;
        font-size: 11px !important;
        align-self: center;
        padding-right: 20px;

        &::after {
          content: '  ';
        }
      }

      &--value {
      }
    }
    .details {
      float: right;
    }
  }
}

@media (max-width: 420px) {
  .navbar-header .navbar-brand img {
    max-width: 220px;
  }
}
`;
