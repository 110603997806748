export const features = {
    "template": "template2",
    "companyName": "Union Mutual Companies (Prod)",
    "inowEndpoint": "https://express.unionmutual.com",
    "tokenIssuer": "https://umv-inow.okta.com/oauth2/default",
    "clientId": "0oa4fjspzjhzBRBdz697",
    "features": {
        "CustomContentPage1": false,
        "CustomContentPage2": false,
        "addRegistrationEmail": true,
        "agentEmail": true,
        "agentLink": true,
        "agentPanel": true,
        "blockAchNumberOps": false,
        "changeCoverage": true,
        "changeCoverageShowNewPremium": false,
        "claimDetailsDocuments": true,
        "claimDetailsPayments": true,
        "claimDocumentUpload": true,
        "claimList": true,
        "claimStatus": false,
        "combinePolicies": true,
        "coverageDetails": true,
        "cpPortalLinking": false,
        "customPrivacyStatement": false,
        "customTOS": false,
        "deactivateUserOnly": true,
        "defaultLossDateTime": true,
        "editPayPlan": false,
        "hasOpenBalance": true,
        "loginPanelExpirationDate": "2999-01-01",
        "mfaEnabled": true,
        "needHelp": true,
        "notificationPreferences": false,
        "otherPaymentAmountAllowed": true,
        "paperlessChoiceUpdate": true,
        "passwordResetInfo": true,
        "payPlanTypesToFilter": [
            "Mortgagee",
            "Agent",
            "Statement"
        ],
        "pendingOrScheduledPaymentsList": true,
        "policyDocumentUpload": true,
        "policyForms": false,
        "policyFormsDownload": false,
        "policyUserAttachmentsDisplay": true,
        "printButtonEnabled": false,
        "recentPaymentsList": true,
        "rememberMe": false,
        "reportClaim": true,
        "scheduledPayments": false,
        "showImageTag": true,
        "standardPhoneLength": true,
        "unauthenticatedPayments": true,
        "unauthenticatedReportClaim": true,
        "useInsuredAttachments": true,
        "verifyAddress": true,
        "viewChangeRequests": true
    },
    "keys": {
        "googleAnalytics": null,
        "googleAnalytics4": null,
        "googleAnalytics4GwDev": "G-R8Y22B4GYL",
        "googleAnalytics4GwProd": "G-C0D8F7WEDX",
        "googlePlacesApi": "AIzaSyAnmgkfZ1It1N0zFcj0JcsCV4NDUFsw72U"
    },
    "salesPortalUrl": "",
    "cpProducts": [
        "Homeowners",
        "Personal Auto"
    ],
    "paymentApiUrl": "https://midas.api.omega2-andromeda.guidewire.net/",
    "paymentTenantName": "tenant.umv",
    "paymentConfigIds": {
        "UMF": "627861",
        "NEG": "627859",
        "CMI": "627860"
    },
    "paymentMethods": [
        "ACH",
        "CC"
    ],
    "anonymousPaymentMethods": [
        "ACH",
        "CC"
    ],
    "achEntryClassCode": "PPD",
    "processingFee": 4.95,
    "maxUploadFileSize": "500mb",
    "attachmentTemplatesLimit": "30",
    "policiesCountLimit": "50",
    "billingAccountsCountLimit": "50",
    "faviconFileExtension": "png",
    "policyCreatedSinceDt": 365,
    "policyStatusCd": [
        "Active",
        "Non-Renewed",
        "Non-Renew Requested",
        "Cancel Notice"
    ],
    "passwordConstraints": [
        {
            "constraint": "minLength",
            "value": 7,
            "message": "Use at least 7 characters"
        },
        {
            "constraint": "minNumber",
            "value": 1,
            "message": "Contain at least one number"
        },
        {
            "constraint": "minLowerCase",
            "value": 1,
            "message": "Contain at least one lower case letter"
        },
        {
            "constraint": "minUpperCase",
            "value": 1,
            "message": "Contain at least one upper case letter"
        }
    ],
    "userIdConstraints": [
        {
            "constraint": "minLength",
            "value": 3,
            "message": "Use at least 3 characters"
        }
    ],
    "downTime": {
        "beginTime": "2024-04-12T15:00-04:00",
        "endTime": "2024-04-15T08:00-04:00"
    },
    "notificationSmsNumber": "123-456-7890",
    "_meta": {
        "build": "4.0.0-14-30df75e",
        "commit": "30df75e379c3d1d6e191180724a38bdf422f3756",
        "company": "umv",
        "date": "2024-04-11T17:53:48+00:00",
        "source": "serviceportal-four-config",
        "version": "4.0.0",
        "imageTag": ""
    }
};